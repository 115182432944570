import { BigNumber, constants, PayableOverrides } from 'ethers'

import { X2Y2Compounder__factory } from '@/lib/contract'
import { X2Y2_COMPOUNDER_CONTRACT_MAPS } from '@/lib/x2y2/contracts'
import { getProviderByNetworkId } from '@/utils/network'

import { NonAnonymousUser, RegisteredUser } from '../auth/types/user'
import { MarketError } from '../market'
import * as Err from '../market/errors'
import { DAYS_PER_YEAR } from './feeSharing'
import { invalidAddress } from './utils'

/**
 * Deposit staked tokens
 *
 * @param user
 * @param amount
 * @returns
 */
export const deposit = async (
  user: RegisteredUser,
  amount: BigNumber,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const compounderContract = X2Y2_COMPOUNDER_CONTRACT_MAPS[networkId]
  if (invalidAddress(compounderContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const compounder = X2Y2Compounder__factory.connect(
    compounderContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await compounder.estimateGas.deposit(amount)
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await compounder.deposit(amount, options)
  await tx.wait()
  return tx.hash
}

/**
 * Withdraw staked tokens
 *
 * @param user
 * @param amount(> 0 && <= sharesValueInX2Y2)
 * @returns
 */
export const withdraw = async (
  user: RegisteredUser,
  amount: BigNumber,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const compounderContract = X2Y2_COMPOUNDER_CONTRACT_MAPS[networkId]
  if (invalidAddress(compounderContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const compounder = X2Y2Compounder__factory.connect(
    compounderContract,
    provider.getSigner(),
  )
  const sharePriceInX2Y2 = await compounder.calculateSharePriceInX2Y2()
  const sharePrice = sharePriceInX2Y2.div(10 ** 9)
  const shares = amount.div(sharePrice).mul(10 ** 9)
  const options: PayableOverrides = {}
  try {
    const gasLimit = await compounder.estimateGas.withdraw(shares)
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await compounder.withdraw(shares, options)
  await tx.wait()
  return tx.hash
}

/**
 * Withdraw all staked tokens
 *
 * @param user
 * @returns
 */
export const withdrawAll = async (user: RegisteredUser): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const compounderContract = X2Y2_COMPOUNDER_CONTRACT_MAPS[networkId]
  if (invalidAddress(compounderContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const compounder = X2Y2Compounder__factory.connect(
    compounderContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await compounder.estimateGas.withdrawAll()
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await compounder.withdrawAll(options)
  await tx.wait()
  return tx.hash
}

/**
 * Calculate value of X2Y2 for a user given a number of shares owned
 *
 * @param user
 * @returns
 */
export const sharesValueInX2Y2 = async (
  user: NonAnonymousUser,
): Promise<BigNumber> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const compounderContract = X2Y2_COMPOUNDER_CONTRACT_MAPS[networkId]
  if (!invalidAddress(compounderContract)) {
    try {
      const jsonRpcProvider = getProviderByNetworkId(networkId)
      const compounder = X2Y2Compounder__factory.connect(
        compounderContract,
        jsonRpcProvider,
      )
      const address = await provider.getSigner().getAddress()
      return await compounder.calculateSharesValueInX2Y2(address)
    } catch (ignored) {}
  }
  return constants.Zero
}

export const compounderAPY = (stakingAPR: {
  WETH: number
  X2Y2: number
}): { wethAPY: number; x2y2APR: number; totalAPY: number } => {
  const wethAPR = stakingAPR.WETH
  const x2y2APR = stakingAPR.X2Y2
  const wethAPY =
    Math.pow(1 + wethAPR / DAYS_PER_YEAR / 100, DAYS_PER_YEAR) * 100 - 100
  const totalAPY = ((100 + wethAPY) * (100 + x2y2APR)) / 100 - 100
  return { wethAPY, x2y2APR, totalAPY }
}
