import { StaticJsonRpcProvider } from '@ethersproject/providers'
import {
  BigNumber,
  constants,
  PayableOverrides,
  providers,
  utils,
} from 'ethers'

import { ExchangeRates } from '@/hooks/useCurrency'
import * as api from '@/lib/api'
import { FeeSharingSystem__factory } from '@/lib/contract'
import { E } from '@/lib/fp'
import { multicallAndBlock } from '@/lib/multicall'
import { stakingRewardData } from '@/lib/store'
import {
  FEE_SHARING_CONTRACT_MAPS,
  TOKEN_DISTRIBUTOR_CONTRACT_MAPS,
} from '@/lib/x2y2/contracts'
import { getProviderByNetworkId } from '@/utils/network'

import { NonAnonymousUser, RegisteredUser } from '../auth/types/user'
import { MarketError } from '../market'
import * as Err from '../market/errors'
import { compounderAPY } from './compounder'
import { invalidAddress } from './utils'

export const BLOCKS_PER_DAY = 7160
export const DAYS_PER_YEAR = 365

const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'numberBlocks',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardPerBlock',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'reward',
        type: 'uint256',
      },
    ],
    name: 'NewRewardPeriod',
    type: 'event',
  },
]
const iface = new utils.Interface(abi)
type RewordLog = {
  block: number
  timestamp: number
  numberBlocks: number
  reward: number
}

/**
 * Deposit staked tokens (and collect reward tokens if requested)
 *
 * @param user
 * @param amount
 * @param claimRewardToken
 * @returns
 */
export const deposit = async (
  user: RegisteredUser,
  amount: BigNumber,
  claimRewardToken: boolean,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (invalidAddress(feeSharingContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const feeSharing = FeeSharingSystem__factory.connect(
    feeSharingContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await feeSharing.estimateGas.deposit(
      amount,
      claimRewardToken,
    )
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await feeSharing.deposit(amount, claimRewardToken, options)
  await tx.wait()
  return tx.hash
}

/**
 * Harvest reward tokens that are pending
 *
 * @param user
 * @returns
 */
export const harvest = async (user: RegisteredUser): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (invalidAddress(feeSharingContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const feeSharing = FeeSharingSystem__factory.connect(
    feeSharingContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await feeSharing.estimateGas.harvest()
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await feeSharing.harvest(options)
  await tx.wait()
  return tx.hash
}

/**
 * Withdraw staked tokens (and collect reward tokens if requested)
 *
 * @param user
 * @param amount(> 0 && <= sharesValueInX2Y2)
 * @param claimRewardToken
 * @returns
 */
export const withdraw = async (
  user: RegisteredUser,
  amount: BigNumber,
  claimRewardToken: boolean,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (invalidAddress(feeSharingContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const feeSharing = FeeSharingSystem__factory.connect(
    feeSharingContract,
    provider.getSigner(),
  )
  const sharePriceInX2Y2 = await feeSharing.calculateSharePriceInX2Y2()
  const sharePrice = sharePriceInX2Y2.div(10 ** 9)
  const shares = amount.div(sharePrice).mul(10 ** 9)
  const options: PayableOverrides = {}
  try {
    const gasLimit = await feeSharing.estimateGas.withdraw(
      shares,
      claimRewardToken,
    )
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await feeSharing.withdraw(shares, claimRewardToken, options)
  await tx.wait()
  return tx.hash
}

/**
 * Withdraw all staked tokens (and collect reward tokens if requested)
 *
 * @param user
 * @param claimRewardToken
 * @returns
 */
export const withdrawAll = async (
  user: RegisteredUser,
  claimRewardToken: boolean,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (invalidAddress(feeSharingContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const feeSharing = FeeSharingSystem__factory.connect(
    feeSharingContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await feeSharing.estimateGas.withdrawAll(claimRewardToken)
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await feeSharing.withdrawAll(claimRewardToken, options)
  await tx.wait()
  return tx.hash
}

/**
 * Calculate value of X2Y2 for a user given a number of shares owned
 *
 * @param user
 * @returns
 */
export const sharesValueInX2Y2 = async (
  user: NonAnonymousUser,
): Promise<BigNumber> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (!invalidAddress(feeSharingContract)) {
    try {
      const jsonRpcProvider = getProviderByNetworkId(networkId)
      const feeSharing = FeeSharingSystem__factory.connect(
        feeSharingContract,
        jsonRpcProvider,
      )
      const address = await provider.getSigner().getAddress()
      return await feeSharing.calculateSharesValueInX2Y2(address)
    } catch (ignored) {}
  }
  return constants.Zero
}

/**
 * Calculate pending rewards (WETH) for a user
 *
 * @param user
 * @returns
 */
export const pendingReward = async (
  user: NonAnonymousUser,
): Promise<BigNumber> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const feeSharingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (!invalidAddress(feeSharingContract)) {
    try {
      const jsonRpcProvider = getProviderByNetworkId(networkId)
      const feeSharing = FeeSharingSystem__factory.connect(
        feeSharingContract,
        jsonRpcProvider,
      )
      const address = await provider.getSigner().getAddress()
      return await feeSharing.calculatePendingRewards(address)
    } catch (ignored) {}
  }
  return constants.Zero
}

export const calcRewardLogs = async (payload: {
  networkId: number
  lastBlock: number
}): Promise<{ block: number; timestamp: number; hash: string }[]> => {
  const etherscanProvider = new providers.EtherscanProvider(
    payload.networkId,
    process.env.ETHERSCAN_API_TOKEN,
  )
  const account = '0xa2dae16d233cc2430ed917880776e2933517b36f'
  const txns: providers.TransactionResponse[] =
    await etherscanProvider.getHistory(account, payload.lastBlock - 30000)
  return txns.map((txn: providers.TransactionResponse) => ({
    block: txn.blockNumber ?? 0,
    timestamp: txn.timestamp ?? 0,
    hash: txn.hash,
  }))
}

const averageReward = (logs: RewordLog[]): number => {
  let totalReword = 0
  let totalBlock = 0
  logs.forEach((t: RewordLog) => {
    totalBlock += t.numberBlocks
    totalReword += t.reward
  })
  return totalReword / totalBlock
}

const calcRewardLog = async (
  provider: StaticJsonRpcProvider,
  txn: { block: number; timestamp: number; hash: string },
) => {
  try {
    const receipt: providers.TransactionReceipt =
      await provider.getTransactionReceipt(txn.hash)
    return receipt.logs
      .map((l) => {
        try {
          const log = iface.parseLog(l)
          if (log && log.name && log.name === 'NewRewardPeriod') {
            return {
              block: txn.block,
              timestamp: txn.timestamp,
              numberBlocks: parseInt(log.args['numberBlocks'].toString()),
              reward: Number(utils.formatEther(log.args['reward'])),
            }
          }
        } catch (ignored) {}
        return undefined
      })
      .find((log) => log)
  } catch (igonred) {}
  return undefined
}

const calcRewardDataByLogs = async (networkId: number, blockNumber: number) => {
  const provider = getProviderByNetworkId(networkId)
  const rewardLogs = await api.rewardData({
    networkId,
    lastBlock: blockNumber,
  })()
  if (E.isRight(rewardLogs)) {
    const logs = (
      await Promise.all(
        rewardLogs.right.map((txn) => calcRewardLog(provider, txn)),
      )
    ).filter((log) => log && log.block && log.timestamp) as RewordLog[]
    if (logs.length > 0) {
      const firstLog = logs[0]
      const lastBlock =
        logs.length > 1 ? logs[logs.length - 1].block : blockNumber
      const lastTimestamp =
        logs.length > 1
          ? logs[logs.length - 1].timestamp
          : (await provider.getBlock(blockNumber)).timestamp
      const blockPerDay = Math.ceil(
        ((lastBlock - firstLog.block) * 86400) /
          (lastTimestamp - firstLog.timestamp),
      )
      return {
        blockPerDay,
        wethPerDay: blockPerDay * averageReward(logs),
      }
    }
  }
  return undefined
}

const calcRewardData = async (
  networkId: number,
  stakingContract: string,
  tokenContract: string,
) => {
  const ts = Math.floor(Date.now() / 1000)
  const srd = stakingRewardData.get()

  const rewardData = E.isRight(srd)
    ? srd.right
    : {
        blockPerDay: 0,
        expiredAt: 0,
        tokenPerDay: 0,
        totalAmount: 0,
        wethPerDay: 0,
      }
  if (rewardData && rewardData.expiredAt > ts) {
    return rewardData
  }
  const resp = await multicallAndBlock(
    networkId,
    [
      {
        inputs: [],
        name: 'rewardPerBlockForStaking',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalAmountStaked',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'currentRewardPerBlock',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    [
      { address: tokenContract, name: 'rewardPerBlockForStaking' },
      { address: tokenContract, name: 'totalAmountStaked' },
      { address: stakingContract, name: 'currentRewardPerBlock' },
    ],
    false,
  )
  const lastBlock = resp.blockNumber.toNumber()
  const data = await calcRewardDataByLogs(networkId, lastBlock)
  if (data) {
    rewardData.blockPerDay = data.blockPerDay
    rewardData.wethPerDay = data.wethPerDay
  } else {
    const reword: BigNumber = resp.data[2][0]
    rewardData.blockPerDay = BLOCKS_PER_DAY
    rewardData.wethPerDay = BLOCKS_PER_DAY * Number(utils.formatEther(reword))
  }
  // const rewardPerBlock: BigNumber = resp.data[0][0]
  const totalAmount: BigNumber = resp.data[1][0]
  rewardData.tokenPerDay = 0
  rewardData.totalAmount = Number(utils.formatEther(totalAmount))
  rewardData.expiredAt = ts + 1800 // 30 mins
  stakingRewardData.set(rewardData)()
  return rewardData
}

export const stakingAPR = async (
  networkId: number,
  rates: ExchangeRates,
): Promise<{
  WETH: number
  X2Y2: number
  dailyWethPer10000x2y2: number
  dailyUSDPer10000x2y2: number
}> => {
  const tokenContract = TOKEN_DISTRIBUTOR_CONTRACT_MAPS[networkId]
  const stakingContract = FEE_SHARING_CONTRACT_MAPS[networkId]
  if (!invalidAddress(tokenContract) && !invalidAddress(stakingContract)) {
    try {
      const rewardData = await calcRewardData(
        networkId,
        stakingContract,
        tokenContract,
      )
      const wethPrice = rates['WETH'] ?? 0
      const x2y2Price = rates['X2Y2'] ?? 0
      const wethDailyApr: number =
        wethPrice === 0 || x2y2Price === 0
          ? 0
          : (rewardData.wethPerDay * wethPrice * 100) /
            (rewardData.totalAmount * x2y2Price)
      const tokenDailyApr: number =
        (rewardData.tokenPerDay * 100) / rewardData.totalAmount
      return {
        WETH: wethDailyApr * DAYS_PER_YEAR,
        X2Y2: tokenDailyApr * DAYS_PER_YEAR,
        dailyWethPer10000x2y2:
          (rewardData.wethPerDay * 10000) / rewardData.totalAmount,
        dailyUSDPer10000x2y2:
          (rewardData.wethPerDay * wethPrice * 10000) / rewardData.totalAmount,
      }
    } catch (ignored) {}
  }
  return { WETH: 0, X2Y2: 0, dailyWethPer10000x2y2: 0, dailyUSDPer10000x2y2: 0 }
}

export type Estimates = {
  x2y2APR: number
  wethAPR: number
  wethAPY: number
  estimateAPR: number
  estimateAPY: number
  dailyWethPer10000x2y2: number
  dailyUSDPer10000x2y2: number
}

export const estimateAPY = async (
  networkId: number,
  rates: ExchangeRates,
): Promise<Estimates> => {
  const apr = await stakingAPR(networkId, rates)
  const apy = compounderAPY(apr)
  return {
    x2y2APR: apr.X2Y2,
    wethAPR: apr.WETH,
    wethAPY: apy.wethAPY,
    estimateAPR: apr.X2Y2 + apr.WETH,
    estimateAPY: apy.totalAPY,
    dailyWethPer10000x2y2: apr.dailyWethPer10000x2y2,
    dailyUSDPer10000x2y2: apr.dailyUSDPer10000x2y2,
  }
}
